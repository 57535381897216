






























































import SurveyItem from '@/components/common/SurveyItem.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import DeleteSurveyPopup from '@/components/common/DeleteSurveyPopup.vue'

@Component({
  components: {
    SurveyItem,
    DeleteSurveyPopup
  }
})
export default class Surveys extends Vue {
  public query = ''
  public limit = 100
  public offset = 0
  public brandId = 0
  public actionSurvey: any = null
  public surveys: any[] = []
  public totalCount = 0
  public busy: boolean = false
  public product: {name: string, section: string, description:string, images: any[]} = {
    name: '',
    section: '',
    description: '',
    images: []
  }
  public productDetails: object = {}
  public QuestionDataExistsInLocalStorage: any = ''
  public DataExistsInLocalStorage: boolean = false
  public draftSurvey: any = ''

  deleteDraft () {
    localStorage.removeItem('SurveyBasicDetails')
    localStorage.removeItem('SurveyQuestionDetails')
    this.surveys = []
    this.getSurveys()
    this.DataExistsInLocalStorage = false
    this.$notify({ type: 'success', text: 'Draft insight has been deleted successfully!' })
  }

  redirectToCreateSurveyPage () {
    this.$router.push('/brand/survey/create?filldraft=true')
  }
  getSurveys (clear = true) {
    this.busy = true
    this.$store.dispatch('getSurveysList', { query: this.query, limit: this.limit, offset: this.offset, brand_id: this.brandId }).then((response) => {
      if (response.options.query === this.query && response.options.limit === this.limit && this.offset === response.options.offset) {
        if (clear) this.surveys = []
        this.surveys.push(...response.response.body.surveys)
        this.totalCount = response.response.body.count
        this.busy = false
      }
    }, (response) => {
      this.busy = false
    })
  }
  checkLocalStorageForBasicDetails () {
    let localStorageBasicDetails = localStorage.getItem('SurveyBasicDetails')
    if (localStorageBasicDetails !== null) {
      this.DataExistsInLocalStorage = true
      let data = localStorage.getItem('SurveyBasicDetails')
      if (data !== null) {
        this.draftSurvey = JSON.parse(data)
      }
      console.log(this.draftSurvey)
    }
  }

  loadMore () {
    this.$data.offset += this.$data.limit
    this.getSurveys(false)
  }

  @Watch('query')
  onSearch () {
    Vue.common.delay(() => {
      this.offset = 0
      this.getSurveys()
    }, 500)()
  }

  created () {
    this.brandId = this.$store.state.userData.brandId
    this.$store.dispatch('getAppSettingsKeyValuePair').then(() => {
      if (this.$store.state.settings.enable_survey_feature === '1') {
        if (this.$store.state.userData.surveyFeature === 1) {
          this.checkLocalStorageForBasicDetails()
          this.getSurveys()
        }
      }
    })
  }

  refresh () {
    this.offset = 0
    this.getSurveys()
  }

  editProductDetails (product:object) {
    this.productDetails = product
  }

  get hasLoadMore (): boolean {
    return this.surveys.length < this.totalCount
  }
}
