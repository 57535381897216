



























































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class SurveyItem extends Vue {
    @Prop() survey!: any
    redirectToEditSurveyPage (surveyId:any) {
      this.$router.push('/brand/insight/' + surveyId + '/edit')
    }
    redirectToSurveyStatsPage (surveyId: any) {
      this.$router.push('/brand/insight/' + surveyId + '/stats')
    }
}
