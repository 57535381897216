var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "green-border-box in-padd white-bg" }, [
    _vm.$store.state.settings.enable_survey_feature == 1
      ? _c("div", [
          _vm.$store.state.userData.surveyFeature == 1
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.surveys, function(survey) {
                      return _c(
                        "div",
                        {
                          key: survey.id,
                          staticClass: "col-xs-12 col-sm-6 item-list"
                        },
                        [
                          _c("SurveyItem", {
                            attrs: { survey: survey },
                            on: {
                              delete: function($event) {
                                _vm.actionSurvey = survey
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "text-center" }, [
                    _vm.hasLoadMore
                      ? _c(
                          "button",
                          {
                            staticClass: "btn-positive",
                            attrs: { disabled: _vm.busy },
                            on: { click: _vm.loadMore }
                          },
                          [_vm._v("Load more")]
                        )
                      : _vm._e()
                  ]),
                  !_vm.busy && (!_vm.surveys || _vm.surveys.length == 0)
                    ? _c("h3", { staticClass: "text-center mt20" }, [
                        _vm._v("\n          No Insight found.\n        ")
                      ])
                    : _vm._e(),
                  _c("DeleteSurveyPopup", {
                    attrs: {
                      brandId: _vm.brandId,
                      popupId: "delete-survey-popup",
                      survey: _vm.actionSurvey
                    },
                    on: { deleted: _vm.refresh }
                  })
                ],
                1
              )
            : _c("div", { staticClass: "row" }, [
                _c("h2", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(
                    "Insights Feature is not available for you. Please contact PICKL support to activate this feature!"
                  )
                ])
              ])
        ])
      : _c("h2", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Insights Feature is not available for you!")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }