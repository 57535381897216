









































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeleteSurveyPopup extends Vue {
  @Prop() popupId!: string;
  @Prop() survey!: any;
  @Prop() brandId!: number

  deleteSurvey () {
    let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
    this.$store.dispatch('deleteSurvey', { brandId: this.brandId, surveyId: this.survey.id }).then(() => {
      this.$emit('deleted')
      popupCloseButton.click()
      this.$notify({ type: 'success', text: 'Insight has been deleted successfully!' })
    }, () => popupCloseButton.click())
  }
}
